export class NotaBasica {
    constructor(
        public _id: String,
        public estudiante:String,
        public materia:String,
        public periodo:String,
        public T1P1insumo1:String,
        public T1P1insumo2:String,
        public T1P1insumo3:String,
        public T1P1insumo4:String,
        public T1P1insumo5:String,
        public ExamenP1T1:String,
        public T1P2insumo1:String,
        public T1P2insumo2:String,
        public T1P2insumo3:String,
        public T1P2insumo4:String,
        public T1P2insumo5:String,
        public Ochenta_P1:String,
        public Prointegradort1:String,
        public ExamenT1:String,
        public Veinte_P1:String,
        public Promedio1:String,
        public T2P1insumo1:String,
        public T2P1insumo2:String,
        public T2P1insumo3:String,
        public T2P1insumo4:String,
        public T2P1insumo5:String,
        public T2P1insumo6:String,
        public T2P1insumo7:String,
        public ExamenP1T2:String,
        public T2P2insumo1:String,
        public T2P2insumo2:String,
        public T2P2insumo3:String,
        public T2P2insumo4:String,
        public T2P2insumo5:String,
        public T2P2insumo6:String,
        public T2P2insumo7:String,
        public Ochenta_P2:String,
        public Prointegradort2:String,
        public ExamenT2:String,
        public Veinte_P2:String,
        public Promedio2:String,
        
        
        
        public T3P1insumo1:String,
        public T3P1insumo2:String,
        public T3P1insumo3:String,
        public T3P1insumo4:String,
        public T3P1insumo5:String,
        public T3P1insumo6:String,
        public T3P1insumo7:String,
        public ExamenP1T3:String,
        public T3P2insumo1:String,
        public T3P2insumo2:String,
        public T3P2insumo3:String,
        public T3P2insumo4:String,
        public T3P2insumo5:String,
        public T3P2insumo6:String,
        public T3P2insumo7:String,
        public Ochenta_P3:String,
        public Prointegradort3:String,
        public ExamenT3:String,
        public Veinte_P3:String,
        public Promedio3:String,
        public examenSupletorio:String,
        public pt:String,
    ) { }
}

