export class Calculable {
    constructor(

        public ochentaporciento1:String,
        public veinteporciento1:String,
        public veinteporcientopi1:String,
        public promedio1:String,
        public promedioponderado1:String,
        public ochentaporciento2:String,
        public veinteporciento2:String,
        public ochentaporciento3:String,
        public veinteporciento3:String,
        public veinteporcientopi2:String,
        public promedio2:String,
        public promedio3:String,
        public promedioponderado2:String,
        public veinteporcientopif:String,
        public promedioPeriodo:String,
        public estadonotabgu:String
        

    ) { }
}

